import {
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  Image,
} from "@chakra-ui/react";
import { ProfileIcon } from "./ProfileIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faEye,
  faHeart,
  faHistory,
  faInfoCircle,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useAuthRef } from "../../providers/use-auth";
import { useNavigate } from "react-router-dom";
import { useDateRate } from "../../providers/use-daterate";

function ReviewViewsRemaining() {
  const [modalOpen, setModalOpen] = useState(false);
  const { userDetailsQuery } = useDateRate();
  const viewsRemaining =
    userDetailsQuery?.data?.metadata?.viewData?.remainingViews;

  if (viewsRemaining === undefined) {
    return null;
  }

  return (
    <>
      <Flex
        alignItems="center"
        bg="rgba(255, 105, 180, 0.1)"
        borderRadius="full"
        padding="2px 8px"
        gap={2}
        onClick={() => setModalOpen(true)}
      >
        <Flex alignItems="center">
          <Text fontSize="lg">👀</Text>
          <Text color="pink.400" fontWeight="bold" fontSize="lg" marginLeft={1}>
            {viewsRemaining}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <FontAwesomeIcon
            icon={faInfoCircle}
            color="rgba(0, 0, 0, 0.25)"
            size="xs"
            style={{ marginLeft: "4px", cursor: "pointer" }}
          />
        </Flex>
      </Flex>

      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="xl" mx={4}>
          <ModalHeader fontSize="xl" fontWeight="bold" textAlign="center">
            profile views
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Text fontSize="md" textAlign="center">
                you have <strong>{viewsRemaining}</strong> profile views
                remaining.
              </Text>
              <Text fontSize="md" textAlign="center">
                to get more views, you can leave a rating on a past date.
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              color="white"
              bg="pink.200"
              w="full"
              onClick={() => setModalOpen(false)}
            >
              okay
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export function Header({
  leftText,
  leftButton,
  leftButtonIcon,
  centerElement,
}: {
  leftText?: string;
  leftButton?: () => void;
  leftButtonIcon?: IconProp;
  centerElement?: React.ReactNode;
}) {
  const [userModalOpen, setUserModalOpen] = useState(false);
  const { logout, jwt } = useAuthRef();
  const navigate = useNavigate();

  return (
    <Flex flexDirection={"column"}>
      <Flex
        width="100vw"
        height="50px"
        justifyContent="space-between"
        alignItems={"center"}
        paddingLeft={5}
        paddingRight={5}
      >
        <Modal isOpen={userModalOpen} onClose={() => setUserModalOpen(false)}>
          <Flex
            position="fixed"
            left={0}
            top={0}
            right={0}
            bottom={0}
            zIndex={1000000}
            backgroundColor="rgba(82, 27, 65, 0.5)"
            flexDirection="column"
            justifyContent="flex-end"
          >
            <Flex flex={1} onClick={() => setUserModalOpen(false)} />
            <Flex
              flexDirection="column"
              bg="white"
              borderTopRadius="20px"
              overflow="hidden"
              boxShadow="0px -4px 10px rgba(0, 0, 0, 0.1)"
            >
              <Flex flexDirection="column" width="100%" py={6} px={4} gap={4}>
                <Button
                  leftIcon={<FontAwesomeIcon icon={faPlus} />}
                  colorScheme="pink"
                  size="lg"
                  justifyContent="flex-start"
                  fontWeight="semibold"
                  onClick={() => {
                    navigate("/");
                    setUserModalOpen(false);
                  }}
                >
                  Search Your Date
                </Button>
                <Button
                  leftIcon={<FontAwesomeIcon icon={faHistory} />}
                  variant="ghost"
                  justifyContent="flex-start"
                  onClick={() => {
                    navigate("/history");
                    setUserModalOpen(false);
                  }}
                  py={6}
                >
                  Recent Views
                </Button>
                <Button
                  leftIcon={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
                  variant="ghost"
                  justifyContent="flex-start"
                  onClick={() => {
                    logout();
                    setUserModalOpen(false);
                  }}
                  py={6}
                  color="red.500"
                >
                  Logout
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Modal>
        <Flex
          flex={1}
          justifyContent="flex-start"
          alignItems="center"
          onClick={leftButton}
          ml={2}
        >
          {leftButtonIcon && (
            <IconButton
              aria-label="Back"
              icon={<FontAwesomeIcon icon={leftButtonIcon} />}
              variant="ghost"
              color="gray.400"
              size="lg"
              fontSize="24px"
              _hover={{ bg: "gray.100" }}
              _active={{ bg: "gray.200" }}
            />
          )}
          {leftText && (
            <Text
              fontSize="lg"
              fontWeight="bold"
              color="pink.500"
              whiteSpace="nowrap"
            >
              {leftText}
            </Text>
          )}
        </Flex>
        <Flex flex={1} alignItems={"center"} justifyContent={"center"}>
          {/* {centerElement ? (
            centerElement
          ) : (
            <Image src={"/logo_pink.png"} boxSize={"2.5em"} />
          )} */}
        </Flex>
        <Flex flex={1} justifyContent={"flex-end"} gap={5}>
          <ReviewViewsRemaining />
          <Flex
            onClick={() => setUserModalOpen(true)}
            alignItems={"center"}
            justifyContent={"flex-end"}
            cursor="pointer"
            color="#F687B3"
            _hover={{ color: "#E9558F" }}
          >
            {jwt && <ProfileIcon />}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
