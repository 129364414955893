import { Button, Flex, Icon, Input, Spinner, Text } from "@chakra-ui/react";
import { DateRateProvider } from "../../providers/use-daterate";
import { useCallback, useMemo, useState } from "react";
import { SloganHeader } from "../common/SloganHeader";
import { Header } from "../common/Header";
import {
  ImageFlowProvider,
  useImageFlow,
} from "../../providers/use-image-flow";
import { faExclamationCircle, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropFileReview } from "../common/DragDropFileReview";

function DateNameInput({ onSubmit }: { onSubmit: (name: string) => void }) {
  const [name, setName] = useState<string>("");
  const [error, setError] = useState<string>("");

  const setDateNameWrapped = useCallback(
    (passedName: string) => {
      if (passedName.length < 3) {
        setError("Name must be at least 3 characters long");
        return;
      }
      onSubmit?.(passedName);
    },
    [onSubmit]
  );

  return (
    <Flex flexDirection={"column"} gap={5} padding={10}>
      <Flex flexDirection={"column"} gap={2}>
        <Input
          placeholder="date's name"
          onChange={(e) => {
            setName(e.target.value);
            setError("");
          }}
          bg={"white"}
          borderColor={error ? "red.500" : "inherit"}
          _hover={{ borderColor: error ? "red.500" : "inherit" }}
          _focus={{ borderColor: error ? "red.500" : "inherit" }}
        />
        {error && (
          <Text color="red.500" fontSize="sm" fontWeight="medium">
            {error}
          </Text>
        )}
      </Flex>
      <Button
        color={"white"}
        bg={"pink.400"}
        _hover={{ bg: "pink.500" }}
        onClick={() => setDateNameWrapped?.(name)}
      >
        continue
      </Button>
    </Flex>
  );
}

function HomeInternal() {
  const { file, setFile, name, setName, flowState, resetState } =
    useImageFlow();

  const homeState = useMemo(() => {
    if (file === null) {
      return "file-selection";
    }
    if (!name) {
      return "date-name";
    }
    return flowState;
  }, [file, flowState, name]);

  if (flowState === "error") {
    return (
      <Flex
        height={"100dvh"}
        width={"100vw"}
        alignItems={"center"}
        justifyContent={"center"}
        display={"flex"}
        flexDirection={"column"}
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          gap={4}
          p={6}
        >
          <Icon
            as={FontAwesomeIcon}
            icon={faExclamationCircle}
            fontSize="3xl"
            color="red.500"
          />
          <Text
            fontSize="lg"
            fontWeight="semibold"
            color="gray.700"
            textAlign="center"
          >
            Verification Failed
          </Text>
          <Text fontSize="sm" color="gray.600" textAlign="center">
            Please try uploading a different photo with a clearly visible face.
          </Text>
          <Button
            width={"100%"}
            height={"40px"}
            mt={2}
            colorScheme="pink"
            leftIcon={<Icon as={FontAwesomeIcon} icon={faRedo} />}
            size="sm"
            onClick={() => resetState()}
          >
            Retry
          </Button>
        </Flex>
      </Flex>
    );
  }
  return (
    <Flex
      height={"100dvh"}
      width={"100vw"}
      alignItems={"center"}
      display={"flex"}
      flexDirection={"column"}
      padding={10}
      bgGradient="linear(to-b, white, pink.100)"
    >
      <Header centerElement={<Flex />} />
      <Flex
        height={"100%"}
        width={"100vw"}
        justifyContent={"center"}
        alignItems={"center"}
        maxWidth={"1000px"}
      >
        <Flex flexDirection={"column"} width={"100%"}>
          <SloganHeader />
          {homeState === "file-selection" && (
            <DragDropFileReview onChange={(file) => setFile?.(file)} />
          )}
          {homeState === "date-name" && (
            <DateNameInput onSubmit={(name) => setName?.(name)} />
          )}
          {homeState === "verify" && (
            <Flex
              flexDirection={"column"}
              width={"100%"}
              alignItems={"center"}
              gap={5}
            >
              <Spinner size={"xl"} color={"pink.200"} />
              <Text color={"gray.500"} fontWeight={"100"}>
                verifying your date's photo...
              </Text>
            </Flex>
          )}
          {homeState === "search" && (
            <Flex
              flexDirection={"column"}
              width={"100%"}
              alignItems={"center"}
              gap={5}
            >
              <Spinner size={"xl"} color={"pink.200"} />
              <Text color={"gray.500"} fontWeight={"100"}>
                searching for your date's tea...
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export function Home() {
  return (
    <ImageFlowProvider>
      <DateRateProvider>
        <HomeInternal />
      </DateRateProvider>
    </ImageFlowProvider>
  );
}
