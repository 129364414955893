import { createContext, useContext, useEffect } from "react";
import { API_URL } from "../constants";
import { useMutation } from "@tanstack/react-query";
import { useAuthRef } from "./use-auth";
import { create } from "zustand";
import {
  ProfileResponse,
  SearchProfileResponse,
  VerifyImageResponse,
} from "../types/image-flow";
import { useNavigate } from "react-router-dom";
import heic2any from "heic2any";

const useImageFlowStore = create<{
  flowState: FlowState;
  setFlowState: (flowState: FlowState) => void;
  file: File | null;
  name: string;
  setFile: (file: File | null) => void;
  setName: (name: string) => void;
  fileUrl: string;
  setFileUrl: (fileUrl: string) => void;
  data: any;
  setData: (data: any) => void;
  searchData: SearchProfileResponse | null;
  setSearchData: (data: SearchProfileResponse | null) => void;
  resetState: () => void;
}>((set) => ({
  flowState: "verify",
  setFlowState: (flowState: FlowState) => set({ flowState }),
  file: null,
  name: "",
  fileUrl: "",
  data: null,
  searchData: null,
  setFile: (file: File | null) => set({ file }),
  setName: (name: string) => set({ name }),
  setFileUrl: (fileUrl: string) => set({ fileUrl }),
  setData: (data: any) => set({ data }),
  setSearchData: (data: SearchProfileResponse | null) =>
    set({ searchData: data }),
  resetState: () =>
    set({
      flowState: "verify",
      file: null,
      name: "",
      fileUrl: "",
      data: null,
      searchData: null,
    }),
}));

async function verifyImageFn(
  file: File,
  name: string,
  jwt?: string
): Promise<VerifyImageResponse> {
  if (!file) throw new Error("No file selected");
  if (!jwt) throw new Error("No JWT provided");

  if (file.type === "image/heic" || file.type === "image/heif") {
    const convertedFileBlob = await heic2any({
      blob: file,
      toType: "image/jpeg",
      quality: 0.7,
    });
    if (Array.isArray(convertedFileBlob)) {
      // If it's an array of blobs, use the first one
      file = new File(
        [convertedFileBlob[0]],
        file.name.replace(/\.heic$/i, ".jpg"),
        { type: "image/jpeg" }
      );
    } else {
      // If it's a single blob
      file = new File(
        [convertedFileBlob],
        file.name.replace(/\.heic$/i, ".jpg"),
        { type: "image/jpeg" }
      );
    }
  }

  const formData = new FormData();
  formData.append("file", file, file.name);
  formData.append("name", name);

  const response = await fetch(`${API_URL}/v1/profile/validate`, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  if (response.status !== 200 && response.status !== 201) {
    throw new Error("Upload failed");
  }
  const responseData = await response.json();
  return responseData;
}

async function searchProfilesFn(
  fileUrl: string,
  jwt?: string
): Promise<SearchProfileResponse | ProfileResponse> {
  if (!fileUrl) throw new Error("No file URL provided");
  if (!jwt) throw new Error("No JWT provided");

  const response = await fetch(`${API_URL}/v1/profile/search`, {
    method: "POST",
    body: JSON.stringify({ imgUrl: fileUrl }),
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  });
  if (response.status !== 200 && response.status !== 201) {
    throw new Error("Search failed");
  }
  const responseData = await response.json();
  return responseData;
}

async function createProfileFn(
  fileUrl: string,
  jwt?: string,
  data?: any
): Promise<ProfileResponse> {
  console.log(fileUrl, jwt, data);
  if (!fileUrl) throw new Error("No file URL provided");
  if (!jwt) throw new Error("No JWT provided");
  if (!data) throw new Error("No data provided");

  const response = await fetch(`${API_URL}/v1/profile/create-new`, {
    method: "POST",
    body: JSON.stringify({ imgUrl: fileUrl, data }),
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  });
  if (response.status !== 200 && response.status !== 201) {
    throw new Error("Create failed");
  }
  const responseData = await response.json();
  return responseData;
}

async function associateProfileFn(
  profileId: string,
  jwt?: string,
  data?: any
): Promise<ProfileResponse> {
  if (!profileId) throw new Error("No profile ID provided");
  if (!jwt) throw new Error("No JWT provided");
  if (!data) throw new Error("No data provided");

  const response = await fetch(`${API_URL}/v1/profile/associate-profile`, {
    method: "POST",
    body: JSON.stringify({ profileId, data }),
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  });
  if (response.status !== 200 && response.status !== 201) {
    throw new Error("Create failed");
  }
  const responseData = await response.json();
  return responseData;
}

type FlowState =
  | "verify"
  | "search"
  | "selecting"
  | "create"
  | "review"
  | "found"
  | "error";

type ImageFlowContextType = {
  flowState: FlowState;
  file: File | null;
  name: string;
  setFile: (file: File) => void;
  setName: (name: string) => void;
  searchData: SearchProfileResponse | null;
  createProfile: () => void;
  associateProfile: (profileId: string) => void;
  loadingAssociation: boolean;
  resetState: () => void;
};

const ImageFlowContext = createContext<ImageFlowContextType>({
  flowState: "verify",
  file: null,
  setFile: () => {},
  name: "",
  setName: () => {},
  searchData: null,
  createProfile: () => {},
  associateProfile: (profileId: string) => {},
  loadingAssociation: false,
  resetState: () => {},
});

export const useImageFlow = () => {
  return useContext(ImageFlowContext);
};

export const ImageFlowProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    flowState,
    setFlowState,
    file,
    setFile,
    name,
    setName,
    fileUrl,
    setFileUrl,
    searchData,
    setSearchData,
    resetState,
  } = useImageFlowStore();
  const { jwt } = useAuthRef();
  const navigate = useNavigate();

  // MUTATIONS

  const {
    mutate: verifyImage,
    isPending: isVerificationPending,
    data: imageVerificationData,
  } = useMutation({
    mutationFn: (file: File) => verifyImageFn(file, name, jwt),
    onSuccess: (data) => {
      setFileUrl(data.imgUrl);
      setFlowState("search");
      searchProfiles();
    },
    onError: (error) => {
      setFlowState("error");
    },
  });

  const { mutate: searchProfiles, isPending: isSearchPending } = useMutation({
    mutationFn: () => searchProfilesFn(fileUrl, jwt),
    onSuccess: (data) => {
      if ("matches" in data) {
        // This is a SearchProfileResponse
        setSearchData(data);
        navigate(`/select`, { state: { data } });
      } else {
        // This is a ProfileResponse
        navigate(`/profile/${data.profileId}`, {
          state: { data, isNew: false, isAssociated: true },
        });
        setFile(null);
        setName("");
      }
    },
    onError: (error) => {
      setFlowState("error");
    },
  });

  const {
    mutate: createProfile,
    isPending: isCreatePending,
    data: createData,
  } = useMutation({
    mutationKey: ["createProfile", fileUrl, jwt, searchData?.data],
    mutationFn: () => createProfileFn(fileUrl, jwt, searchData?.data),
    onSuccess: (data) => {
      setFlowState("review");
      navigate(`/profile/${data.profileId}`, {
        state: { data, isNew: true, isAssociated: true },
      });
      setFile(null);
      setName("");
    },
    onError: (error) => {
      setFlowState("error");
    },
  });

  const {
    mutate: associateProfile,
    isPending: isAssociatePending,
    data: associateData,
  } = useMutation({
    mutationFn: (profileId: string) =>
      associateProfileFn(profileId, jwt, searchData?.data),
    onSuccess: (data) => {
      setFlowState("found");

      navigate(`/profile/${data.profileId}`, {
        state: { data, isNew: false, isAssociated: true },
      });
    },
    onError: (error) => {
      setFlowState("error");
    },
  });

  // EFFECTS

  useEffect(() => {
    if (file && name) {
      if (!jwt) {
        navigate("/login");
      } else {
        if (flowState === "verify") {
          verifyImage(file);
        }
      }
    }
  }, [file, name, jwt, navigate, verifyImage, flowState]);

  return (
    <ImageFlowContext.Provider
      value={{
        flowState,
        file,
        setFile,
        name,
        setName,
        searchData,
        createProfile,
        associateProfile,
        loadingAssociation: isAssociatePending,
        resetState,
      }}
    >
      {children}
    </ImageFlowContext.Provider>
  );
};
