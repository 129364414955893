import { Home } from "./Home";
import { Login } from "./Login";
import { Select } from "./Select";
import { Profile } from "./Profile";
import { PastDates } from "./PastDates";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/select",
    element: <Select />,
  },
  {
    path: "/profile/:profileId",
    element: <Profile />,
  },
  {
    path: "/history",
    element: <PastDates />,
  },
]);

export function Router() {
  // const { applicationState } = useDateRateRef();

  // if (!applicationState) {
  //   return <Spinner />;
  // }

  // switch (applicationState) {
  //   case "upload":
  //     return <Home />;
  //   case "uploading":
  //     return <Home />;
  //   case "rate":
  //     return <Rate />;
  //   case "login":
  //     return <Login />;
  //   case "select":
  //     return <Select />;
  //   case "review":
  //     return (
  //       <ReviewProvider>
  //         <ReviewRouter />
  //       </ReviewProvider>
  //     );
  //   default:
  //     return <></>;
  // }
  return <RouterProvider router={router} />;
}
