import { createContext, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { API_URL } from "../constants";
import { useAuthRef } from "./use-auth";
import { ProfileResponse } from "../types/image-flow";
import { ReviewData } from "../types/review";

type ProfileRefContextType = {
  profileData?: ProfileResponse;
  loadingProfileData: boolean;
  refetchProfileData: () => void;
  reviewData?: ReviewData;
  loadingReviewData: boolean;
  refetchReviewData: () => void;
};

const ProfileContext = createContext<ProfileRefContextType>({
  profileData: undefined,
  loadingProfileData: false,
  refetchProfileData: () => {},
  reviewData: undefined,
  loadingReviewData: false,
  refetchReviewData: () => {},
});

export const useProfile = () => {
  return useContext(ProfileContext);
};

export const ProfileProvider = ({
  children,
  profileId,
}: {
  children: React.ReactNode;
  profileId?: string;
}) => {
  const { jwt } = useAuthRef();

  const {
    data: profileData,
    isLoading: loadingProfileData,
    refetch: refetchProfileData,
  } = useQuery({
    queryKey: ["potentialProfiles", profileId, jwt],
    queryFn: async () => {
      if (!profileId) return null;
      if (!jwt) return null;
      const response = await fetch(
        `${API_URL}/v1/profile/details?profileId=${profileId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return await response.json();
    },
  });

  const {
    data: reviewData,
    isLoading: loadingReviewData,
    refetch: refetchReviewData,
  } = useQuery({
    queryKey: ["reviewData", profileId, jwt],
    queryFn: async () => {
      if (!profileId) return null;
      if (!jwt) return null;
      const response = await fetch(
        `${API_URL}/v1/user/get-review?profileId=${profileId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return (await response.json()).userReview as ReviewData;
    },
  });

  return (
    <ProfileContext.Provider
      value={{
        profileData,
        reviewData: reviewData ?? undefined,
        loadingProfileData,
        loadingReviewData,
        refetchProfileData,
        refetchReviewData,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
