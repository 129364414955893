import { Card, Flex, Text } from "@chakra-ui/react";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Header } from "../common/Header";
import { useNavigate } from "react-router-dom";
import { Match } from "../../types/image-flow";
import {
  ImageFlowProvider,
  useImageFlow,
} from "../../providers/use-image-flow";

function ProfileCard({
  profile,
  onClick,
}: {
  profile: { score: number; imgUrls: string[]; profileId: string };
  onClick: () => void;
}) {
  return (
    <Card
      flex={1}
      backgroundImage={`url(${profile.imgUrls[0]})`}
      backgroundSize={"cover"}
      backgroundPosition={"center"}
      borderRadius={"10px"}
      position="relative"
      overflow="hidden"
      onClick={onClick}
      cursor="pointer"
      transition="transform 0.2s, box-shadow 0.2s"
      _hover={{
        transform: "scale(1.05)",
        boxShadow: "lg",
      }}
      _active={{
        transform: "scale(0.95)",
      }}
    >
      <Flex
        position="absolute"
        top="0"
        right="0"
        transform="rotate(45deg) translate(30%, -25%)"
        bg="pink.500"
        color="white"
        opacity={0.8}
        px={7}
        py={1}
        fontSize="0.7rem"
        fontWeight="bold"
      >
        {Math.round(profile.score)}% match
      </Flex>
    </Card>
  );
}

function CreateProfileCard() {
  const { createProfile } = useImageFlow();

  return (
    <Card
      flex={1}
      bg={"clear"}
      border={"4px solid"}
      fontSize={"1.2rem"}
      fontWeight={"bold"}
      color={"pink.200"}
      borderColor={"pink.200"}
      borderRadius={"10px"}
      alignItems={"center"}
      justifyContent={"center"}
      onClick={() => {
        createProfile?.();
      }}
      cursor="pointer"
      transition="transform 0.2s, box-shadow 0.2s"
      _hover={{
        transform: "scale(1.05)",
        boxShadow: "lg",
      }}
      _active={{
        transform: "scale(0.95)",
      }}
    >
      <Text align={"center"}>don't see your date?</Text>
    </Card>
  );
}

function SelectInner() {
  const { searchData, resetState } = useImageFlow();
  const navigate = useNavigate();
  // Group profiles into pairs
  const groupedProfiles = searchData?.matches?.reduce<Array<Array<Match>>>(
    (result, profile, index) => {
      if (index % 2 === 0) {
        result.push([profile]);
      } else {
        result[result.length - 1].push(profile);
      }
      return result;
    },
    []
  );

  console.log(searchData);

  if (searchData?.canCreateNew) {
    groupedProfiles?.push([{ score: 0, imgUrls: [], profileId: "-1" }]);
  }

  return (
    <Flex
      flex={1}
      style={{ width: "100vw", height: "100dvh" }}
      justifyContent={"center"}
      flexDirection={"column"}
    >
      <Flex flex={0} padding={5} />
      <Header
        leftButton={() => {
          resetState();
          navigate("/");
        }}
        leftButtonIcon={faHome}
      />
      <Flex
        gap={2}
        flexDirection={"column"}
        maxWidth={"500px"}
        flex={1}
        padding={5}
      >
        {groupedProfiles?.map((pair, index) => (
          <Flex key={index} gap={2} flex={1} maxHeight={"min(500px, 40vw)"}>
            {pair[0] &&
              (pair[0].profileId === "-1" ? (
                <CreateProfileCard />
              ) : (
                <ProfileCard
                  key={pair[0].profileId}
                  profile={pair[0]}
                  onClick={() => {
                    navigate(`/profile/${pair[0].profileId}`, {
                      state: { isAssociated: false, isNew: false },
                    });
                  }}
                />
              ))}
            {pair[1] ? (
              pair[1].profileId === "-1" ? (
                <CreateProfileCard />
              ) : (
                <ProfileCard
                  key={pair[1].profileId}
                  profile={pair[1]}
                  onClick={() => {
                    navigate(`/profile/${pair[1].profileId}`, {
                      state: { isAssociated: false, isNew: false },
                    });
                  }}
                />
              )
            ) : (
              <Flex flex={1} />
            )}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}

export function Select() {
  return (
    <ImageFlowProvider>
      <SelectInner />
    </ImageFlowProvider>
  );
}
