import { ArrowBackIcon, ArrowForwardIcon, CheckIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Icon, Spinner, Text } from "@chakra-ui/react";
import { useReviewRef } from "../../providers/use-review";
import { useCallback, useMemo, useState } from "react";
import { DateRateProvider } from "../../providers/use-daterate";
import { REVIEW_CATEGORIES, TILE_OPTIONS } from "../../data/review";
import { ReviewTileOptions } from "../review/ReviewTileOptions";
import { ReviewStarOptions } from "../review/ReviewStarOptions";
import { ReviewData } from "../../types/review";

function ReviewHeader({
  widget,
  onBack,
  onCancel,
  onSkip,
}: {
  widget: JSX.Element;
  onNext: () => void;
  onBack: () => void;
  onCancel?: () => void;
  onSkip?: (index: number) => void;
}) {
  const { reviewIndex, setReviewIndex, reviewData } = useReviewRef();

  const reviewPages = [
    {
      systemName: "MEET_LOCATION",
      type: "dropdown",
      prettyName: "meeting",
    },
    ...Object.entries(REVIEW_CATEGORIES).map(([key, category]) => ({
      systemName: key,
      type: "ratings",
      prettyName: category.prettyName,
    })),
  ];

  return (
    <>
      <Flex
        overflowX="auto"
        width="100%"
        py={3}
        px={4}
        position="sticky"
        top={0}
        bg="white"
        zIndex={1}
        borderBottom="1px solid rgba(0, 0, 0, 0.1)"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          maxWidth="100vw"
          overflowX="scroll"
          gap={5}
          css={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
          }}
          ref={(el) => {
            if (el) {
              const activeElement = el.children[reviewIndex] as HTMLElement;
              if (activeElement) {
                const scrollLeft =
                  activeElement.offsetLeft -
                  el.clientWidth / 2 +
                  activeElement.offsetWidth / 2;
                el.scrollTo({
                  left: scrollLeft,
                  behavior: "smooth",
                });
              }
            }
          }}
        >
          {Array.from({ length: reviewPages.length }, (_, i) => (
            <Flex
              key={i}
              flexDirection="column"
              alignItems="center"
              minWidth="60px"
              mx={1}
              onClick={() => setReviewIndex(i)}
              cursor="pointer"
            >
              {reviewData[reviewPages[i].type as keyof ReviewData][
                reviewPages[i].systemName as keyof ReviewData
              ] ? (
                <Icon as={CheckIcon} color="green.500" boxSize={3} mb={2} />
              ) : (
                <Box
                  width="10px"
                  height="10px"
                  borderRadius="50%"
                  bg={i === reviewIndex ? "pink.500" : "gray.300"}
                  mb={2}
                />
              )}
              <Text
                fontSize="xs"
                color={i === reviewIndex ? "pink.500" : "gray.500"}
                whiteSpace={"nowrap"}
              >
                {reviewPages[i].prettyName}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Flex>

      <Flex
        flexDirection={"column"}
        gap={5}
        width={"100%"}
        height={"77%"}
        overflowY={"scroll"}
      >
        {widget}
      </Flex>

      <Flex
        position="fixed"
        bottom={0}
        left={0}
        width="100%"
        backgroundColor="white"
        justifyContent="space-between"
        alignItems="center"
        p={3}
        boxShadow="0 -1px 4px rgba(0, 0, 0, 0.1)"
      >
        {reviewIndex > 0 && (
          <Button
            leftIcon={<ArrowBackIcon />}
            colorScheme="gray"
            variant="ghost"
            size="md"
            onClick={() => onBack()}
            justifyContent="flex-start"
            height="50px"
            fontSize="md"
            borderRadius="full"
          >
            Back
          </Button>
        )}
        {onCancel && (
          <Button
            colorScheme="red"
            variant="ghost"
            size="md"
            onClick={() => onCancel?.()}
            mx={2}
            height="50px"
            fontSize="md"
            borderRadius="full"
          >
            Cancel
          </Button>
        )}
        <Button
          rightIcon={<ArrowForwardIcon />}
          colorScheme="gray"
          variant="ghost"
          size="sm"
          onClick={() => onSkip?.(reviewIndex)}
          height="40px"
          fontSize="sm"
          borderRadius="full"
          px={4}
        >
          Skip
        </Button>
      </Flex>
    </>
  );
}

function ReviewLoading() {
  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"100dvh"}
      width={"100vw"}
      gap={5}
      color={"pink.500"}
    >
      <Spinner size="xl" />
      <Text fontSize={"1rem"} fontWeight={"500"}>
        rating...
      </Text>
    </Flex>
  );
}

function ReviewRouterInner({ onClose }: { onClose: () => void }) {
  const [data, setData] = useState<Partial<ReviewData>>({});
  const { reviewIndex, onNext, onBack } = useReviewRef();

  const setDataRating = useCallback(
    (key: string) => (rating: number) => {
      setData((prev) => ({
        ...prev,
        ratings: { ...prev.ratings, [key]: rating },
      }));
      onNext(
        {
          ...data,
          ratings: { ...data.ratings, [key]: rating },
        } as ReviewData,
        onClose
      );
    },
    [setData, onNext, data, onClose]
  );

  const setDataDropdown = useCallback(
    (key: string) => (option: string) => {
      setData((prev) => ({
        ...prev,
        dropdown: { ...prev.dropdown, [key]: option },
      }));
      onNext(
        {
          ...data,
          dropdown: { ...data.dropdown, [key]: option },
        } as ReviewData,
        onClose
      );
    },
    [setData, onNext, data, onClose]
  );

  const reviewComponentMap: {
    key: string;
    widget: JSX.Element;
    type: string;
  }[] = useMemo(() => {
    const reviewComponents = Object.entries(REVIEW_CATEGORIES).map(
      ([key, category]) => ({
        key,
        type: "rating",
        widget: (
          <ReviewStarOptions
            category={category}
            onChange={setDataRating(key)}
          />
        ),
      })
    );
    reviewComponents.unshift({
      key: "MEET_LOCATION",
      type: "dropdown",
      widget: (
        <ReviewTileOptions
          options={TILE_OPTIONS}
          onClick={setDataDropdown("MEET_LOCATION")}
        />
      ),
    });
    return reviewComponents;
  }, [setDataRating, setDataDropdown]);

  const onSkipRating = useCallback(
    (key: string) => {
      setData((prev) => ({ ...prev, ratings: { ...prev.ratings, [key]: 0 } }));
      onNext(
        { ...data, ratings: { ...data.ratings, [key]: 0 } } as ReviewData,
        onClose
      );
    },
    [setData, onNext, data, onClose]
  );

  const onSkipDropdown = useCallback(
    (key: string) => {
      console.log(key);
      setData((prev) => ({
        ...prev,
        dropdown: { ...prev.dropdown, [key]: "" },
      }));
      onNext(
        {
          ...data,
          dropdown: { ...data.dropdown, [key]: "" },
        } as ReviewData,
        onClose
      );
    },
    [setData, onNext, data, onClose]
  );

  const onSkip = useCallback(
    (index: number) => {
      const reviewItem = Object.values(reviewComponentMap)[index];
      console.log(reviewItem);
      if (reviewItem.type === "rating") {
        onSkipRating(reviewItem.key);
      } else {
        onSkipDropdown(reviewItem.key);
      }
    },
    [onSkipRating, onSkipDropdown, reviewComponentMap]
  );

  const widget = useMemo(() => {
    return reviewComponentMap[reviewIndex]?.widget;
  }, [reviewIndex, reviewComponentMap]);

  if (reviewIndex === reviewComponentMap.length) {
    return <ReviewLoading />;
  }

  return (
    <Flex
      flexDirection={"column"}
      height={"100dvh"}
      width={"100vw"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      gap={5}
    >
      <ReviewHeader
        widget={widget}
        onNext={() => onNext(data as ReviewData, onClose)}
        onSkip={onSkip}
        onBack={() => onBack()}
        onCancel={onClose}
      />
    </Flex>
  );
}

export function ReviewRouter({ onClose }: { onClose: () => void }) {
  return (
    <DateRateProvider>
      <ReviewRouterInner onClose={onClose} />
    </DateRateProvider>
  );
}
