import { Image, Box, Flex, Icon, SimpleGrid, Text } from "@chakra-ui/react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function ReviewTileOptions({
  options,
  onClick,
}: {
  options: {
    prettyName: string;
    systemName: string;
    imagePath?: string;
    emoji?: string;
    color?: string;
    icon?: IconDefinition;
    bg?: string;
  }[];
  onClick: (option: string) => void;
}) {
  return (
    <Flex
      flexDirection="column"
      gap={3}
      width="100%"
      maxWidth="400px"
      mx="auto"
      minHeight="100%"
      p={4}
    >
      <Flex flexDirection="column" alignItems="center" textAlign="center">
        <Text
          fontSize="2xl"
          fontWeight="bold"
          bgGradient="linear(to-r, pink.300, purple.300)"
          bgClip="text"
        >
          Where did you meet?
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center" flex={1}>
        <SimpleGrid columns={3} spacing={4} width="100%" maxWidth="400px">
          {options.map((option) => (
            <Box
              key={option.systemName}
              as="button"
              aspectRatio={1}
              borderRadius="xl"
              bg={option.bg || "white"}
              boxShadow="md"
              _hover={{ transform: "scale(1.05)", boxShadow: "lg" }}
              onClick={() => {
                onClick(option.systemName);
              }}
              cursor="pointer"
              transition="all 0.3s"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              color={option.color || "gray.800"}
              p={3}
            >
              <Flex
                flexDirection="column"
                alignItems="center"
                gap={3}
                width="100%"
                height="100%"
                justifyContent="center"
              >
                {option.icon && (
                  <Icon
                    as={FontAwesomeIcon}
                    icon={option.icon}
                    color={option.color}
                    boxSize="2em"
                  />
                )}
                {option.imagePath && (
                  <Image
                    src={option.imagePath}
                    width="2em"
                    height="2em"
                    objectFit="contain"
                  />
                )}
                {option.emoji && <Text fontSize="2em">{option.emoji}</Text>}
                <Text textAlign="center" fontSize="lg" fontWeight="bold">
                  {option.prettyName}
                </Text>
              </Flex>
            </Box>
          ))}
        </SimpleGrid>
      </Flex>
    </Flex>
  );
}
