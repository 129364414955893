import {
  Badge,
  Button,
  Card,
  Collapse,
  Divider,
  Flex,
  Icon,
  Spinner,
  Text,
  Tooltip,
  Image,
  VStack,
  Box,
} from "@chakra-ui/react";
import { createTheme, Rating, ThemeProvider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCalendarWeek,
  faChartLine,
  faCheckCircle,
  faChevronLeft,
  faDollar,
  faEdit,
  faExclamationCircle,
  faFaceLaugh,
  faFeather,
  faFish,
  faHeart,
  faHome,
  faInfoCircle,
  faLock,
  faMask,
  faQuestionCircle,
  faTimesCircle,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { GradientSlider } from "../common/GradientSlider";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import { Header } from "../common/Header";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useProfile } from "../../providers/use-profile";
import { ProfileResponse } from "../../types/image-flow";
import { useImageFlow } from "../../providers/use-image-flow";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { REVIEW_CATEGORIES } from "../../data/review";
import { QuestionIcon } from "@chakra-ui/icons";
import { ActiveOn } from "../profile/ActiveOn";

const theme = createTheme({});

function ProfileHeader({
  profileData,
  reviewMode,
}: {
  profileData: ProfileResponse;
  reviewMode?: boolean;
}) {
  const [key, setKey] = useState(0);
  const numberReviews = profileData?.reviewData?.numberReviews ?? "?";
  const numberViews = profileData?.reviewData?.numberViews ?? "?";
  const totalScores = Object.values(
    profileData?.reviewData?.ratings ?? {}
  ).reduce<number>((acc, curr) => acc + (typeof curr === "number" ? 1 : 0), 0);
  const totalRating =
    Object.values(profileData?.reviewData?.ratings ?? {}).reduce<number>(
      (acc, curr) => acc + curr,
      0
    ) / totalScores;

  useEffect(() => {
    setKey(totalRating);
  }, [totalRating]);

  const images = profileData?.imgUrls.map((url) => ({
    original: url,
    thumbnail: url,
  }));
  console.log(profileData?.imgUrls);
  return (
    <Flex
      alignItems={"center"}
      width={"calc(100vw - 20px)"}
      aspectRatio={"1/1"}
      maxWidth={"40em"}
      gap={2}
      flexDirection={"column"}
      position={"relative"}
    >
      <ImageGallery
        showPlayButton={false}
        showFullscreenButton={false}
        showThumbnails={false}
        items={images}
        showBullets={true}
      />
      <Flex
        style={{
          borderRadius: "5px",
          width: "calc(100vw - 20px)",
          height: "100%",
          maxWidth: "40em",
        }}
        pointerEvents={"none"}
        position={"absolute"}
      >
        <Flex
          flexDirection={"row"}
          gap={0}
          width={"100%"}
          height={"100%"}
          alignItems={"flex-end"}
          justifyContent={"space-between"}
          padding={5}
        >
          <Flex flexDirection={"column"} gap={0}>
            <Flex alignItems={"center"} gap={2}>
              <Text
                fontSize={"1.5rem"}
                fontWeight={"bold"}
                color={"white"}
                textShadow="2px 2px 4px rgba(0,0,0,0.5)"
              >
                {(profileData?.name?.charAt(0).toUpperCase() ?? "") +
                  (profileData?.name?.slice(1) ?? "")}
              </Text>

              {!reviewMode && (
                <Flex flexDirection={"row"}>
                  <ThemeProvider theme={theme}>
                    <Rating
                      key={key}
                      style={{
                        fontSize: "1.25rem",
                        filter: "drop-shadow(4px 4px 4px rgba(0,0,0,0.5))",
                      }}
                      name="half-rating-read"
                      defaultValue={totalRating}
                      precision={0.5}
                      readOnly
                    />
                  </ThemeProvider>
                </Flex>
              )}
            </Flex>
            {!reviewMode && (
              <Flex
                w="100%"
                flexDirection={"row"}
                gap={2}
                justifyContent={"flex-start"}
              >
                {profileData?.reviewData?.hasReviewed && (
                  <Badge
                    colorScheme="green"
                    fontSize={"0.65rem"}
                    fontWeight={"bold"}
                    paddingX={2}
                    paddingY={1}
                    borderRadius={"full"}
                    height={"fit-content"}
                  >
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp; reviewed
                  </Badge>
                )}
                <Badge
                  size="xs"
                  colorScheme="pink"
                  fontSize={"0.65rem"}
                  fontWeight={"bold"}
                  paddingX={2}
                  paddingY={1}
                  height={"fit-content"}
                  borderRadius={"full"}
                >
                  <Text>
                    {numberReviews} review{numberReviews === 1 ? "" : "s"}
                  </Text>
                </Badge>
                <Badge
                  size="xs"
                  colorScheme="yellow"
                  fontSize={"0.65rem"}
                  fontWeight={"bold"}
                  paddingX={2}
                  paddingY={1}
                  height={"fit-content"}
                  borderRadius={"full"}
                >
                  <Text>
                    {numberViews} view
                    {numberViews === 1 ? "" : "s"}
                  </Text>
                </Badge>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

function Flag({
  type,
  text,
}: {
  type: "green" | "yellow" | "red";
  text: string;
}) {
  return (
    <Flex gap={3} alignItems={"center"}>
      <img
        src={`/${type}flag.png`}
        alt="us"
        width="15px"
        height="15px"
        style={{ width: "15px", height: "15px" }}
      />
      {text.replace("_", " ")}
    </Flex>
  );
}

function RatingRow({
  category,
  rating,
  icon,
  iconColor,
}: {
  category: string;
  rating?: number;
  icon: IconDefinition;
  iconColor: string;
}) {
  const [key, setKey] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const option = REVIEW_CATEGORIES[category]?.options?.find(
    (option) => option.stars === Math.round(rating ?? 0)
  );

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [rating]);

  return (
    <Flex
      flexDirection="column"
      borderWidth={1}
      borderRadius="md"
      p={4}
      cursor="pointer"
      boxShadow="sm"
      opacity={rating !== undefined ? 1 : 0.5}
      w="100%"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setExpanded(!expanded);
      }}
      _active={{
        bg: "gray.100",
      }}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        flexDirection="column"
        width="100%"
      >
        <Flex alignItems="center" width="100%" mb={2}>
          <FontAwesomeIcon icon={icon} color={iconColor} />
          <Text ml={2} color="gray.600">
            {REVIEW_CATEGORIES[category]?.prettyName}
          </Text>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Flex flex={1} alignItems="center">
            <Flex flexDirection="column" width="100%">
              <Text
                fontWeight="bold"
                fontSize={["md", "lg"]}
                noOfLines={1}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {option?.title}
              </Text>
            </Flex>
          </Flex>
          <Flex alignItems="center" flexShrink={0}>
            <ThemeProvider theme={theme}>
              <Rating
                key={key}
                name={`option-${category}`}
                value={rating}
                readOnly
                size="small"
              />
            </ThemeProvider>

            <Flex alignItems="center" height="" position="relative">
              <Flex
                position="absolute"
                width="calc(100% + 20px)"
                height="calc(100% + 30px)"
              />
              <Divider
                orientation="vertical"
                height="20px"
                mx={2}
                borderColor="gray.400"
              />
              <Icon
                as={QuestionIcon}
                aria-label="More information"
                color="gray.500"
                pointerEvents="none"
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Collapse in={expanded} animateOpacity>
        <Text fontSize="sm" color="gray.600" mt={2}>
          {option?.description}
        </Text>
      </Collapse>
    </Flex>
  );
}

function FlagCard({
  flags,
  type,
}: {
  flags: string[];
  type: "green" | "yellow" | "red";
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  if (!flags.length)
    return (
      <Card bg={`${type}.100`} width={"100%"} padding={5}>
        <Flex flexDirection={"column"} gap={3}>
          <FontAwesomeIcon
            icon={faQuestionCircle}
            color={"rgba(0,0,0,0.1)"}
            size={"2xl"}
          />
        </Flex>
      </Card>
    );
  return (
    <Card bg={`${type}.100`} width={"100%"} padding={5}>
      <Tooltip
        label={`date's ${type} flags`}
        isOpen={tooltipOpen}
        placement="left"
      >
        <FontAwesomeIcon
          icon={faInfoCircle}
          color={"rgba(0,0,0,0.2)"}
          size={"lg"}
          style={{ position: "absolute", top: 5, right: 5 }}
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
        />
      </Tooltip>
      <Flex flexDirection={"column"} gap={3}>
        {flags.map((flag) => (
          <Flag type={type} text={flag} />
        ))}
      </Flex>
    </Card>
  );
}

function ProfileFlags({ profileData }: { profileData: ProfileResponse }) {
  const { flags } = profileData.reviewData;

  const greenFlags: string[] = flags.GREEN?.map((flag: any) => flag.name) ?? [];
  const redFlags: string[] = flags.RED?.map((flag: any) => flag.name) ?? [];
  return (
    <Flex alignItems={"center"} width={"100%"} gap={5} flexDirection={"column"}>
      <FlagCard flags={greenFlags} type="green" />
      <FlagCard flags={redFlags} type="red" />
    </Flex>
  );
}

function ReviewRow({
  title,
  rating,
  icon,
  iconColor,
}: {
  title: string;
  rating?: number;
  icon: IconDefinition;
  iconColor: string;
}) {
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [rating]);

  return (
    <Flex
      width={"100%"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      gap={2}
      opacity={rating !== undefined ? 1 : 0.2}
    >
      <Flex alignItems={"center"} gap={2}>
        <FontAwesomeIcon icon={icon} color={iconColor} />
        <Text fontWeight={"400"} color={"gray.700"}>
          {title}
        </Text>
      </Flex>
      <ThemeProvider theme={theme}>
        <Rating
          key={key}
          name="half-rating-read-small"
          value={rating ?? 0}
          onClick={() => rating}
          precision={0.5}
          size="medium"
          readOnly
        />
      </ThemeProvider>
    </Flex>
  );
}

function ProfileStars({ profileData }: { profileData: ProfileResponse }) {
  const { reviewData } = profileData;
  const { ratings } = reviewData;

  return (
    <Flex
      alignItems={"flex-start"}
      width={"100%"}
      gap={2}
      flexDirection={"column"}
    >
      <Flex
        width={"100%"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={2}
      >
        <RatingRow
          category="safety"
          rating={ratings.safety}
          icon={faLock}
          iconColor={"#86cfb4"}
        />
        <RatingRow
          category="humor"
          rating={ratings.humor}
          icon={faFaceLaugh}
          iconColor={"#cd8bff"}
        />
        {/* <RatingRow
          category="datenight"
          rating={ratings.datenight}
          icon={faCalendar}
          iconColor={"#5e69ff"}
        /> */}
        <RatingRow
          category="bedroom"
          rating={ratings.bedroom}
          icon={faHeart}
          iconColor={"#FF69B4"}
        />
        <RatingRow
          category="catfish"
          rating={ratings.catfish}
          icon={faFish}
          iconColor={"#ff8d02"}
        />

        <RatingRow
          category="cheap-expensive"
          rating={ratings["cheap-expensive"]}
          icon={faDollar}
          iconColor={"#239836"}
        />
        <RatingRow
          category="kinkiness"
          rating={ratings.kinkiness}
          icon={faMask}
          iconColor={"#d84747"}
        />
      </Flex>
    </Flex>
  );
}

function ProfileCalendar({ profileData }: { profileData: ProfileResponse }) {
  const { reviewData } = profileData;
  const dates =
    reviewData?.dates?.map((date: any) => ({
      date: date.date,
      count: parseInt(date.cnt),
    })) ?? [];
  return (
    <Flex
      width={"100%"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      flexDirection={"column"}
      gap={1}
    >
      <Text color={"gray.600"} fontWeight={"bold"} fontSize={"1rem"}>
        monthly date activity
      </Text>
      <CalendarHeatmap
        horizontal={false}
        showMonthLabels={false}
        startDate={new Date(new Date().setMonth(new Date().getMonth() - 1))}
        endDate={new Date()}
        values={dates}
        classForValue={(value) => {
          if (!value) {
            return "color-empty";
          }
          return `color-scale-${value.count}`;
        }}
      />
    </Flex>
  );
}

function ProfileActivity({ profileData }: { profileData: ProfileResponse }) {
  const { reviewData } = profileData;
  const dates =
    reviewData?.dates?.map((date: any) => ({
      date: date.date,
      count: parseInt(date.cnt),
    })) ?? [];

  const now = new Date();
  const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
  const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

  const thisMonth = dates.filter(
    (d: any) => new Date(d.date) >= thirtyDaysAgo
  ).length;
  const thisWeek = dates.filter(
    (d: any) => new Date(d.date) >= sevenDaysAgo
  ).length;

  const avgPerWeek = (dates.length / 4).toFixed(1);

  return (
    <Box
      width="100%"
      bg="white"
      borderRadius="3xl"
      overflow="hidden"
      boxShadow="0 10px 30px -5px rgba(0, 0, 0, 0.1)"
      my={8}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        bg="linear-gradient(135deg, #FFA6D5, #FF69B4)"
        py={4}
        px={6}
      >
        <Icon
          as={FontAwesomeIcon}
          icon={faCalendar}
          color="white"
          mr={3}
          fontSize="2xl"
        />
        <Text fontSize="xl" fontWeight="bold" color="white">
          Date Activity
        </Text>
      </Flex>
      <VStack spacing={0} divider={<Divider />}>
        <ActivityCard
          label="This Month"
          value={thisMonth}
          icon={faCalendar}
          color="pink.400"
        />
        <ActivityCard
          label="This Week"
          value={thisWeek}
          icon={faCalendarWeek}
          color="purple.400"
        />
        <ActivityCard
          label="Weekly Average"
          value={avgPerWeek}
          icon={faChartLine}
          color="blue.400"
        />
      </VStack>
    </Box>
  );
}

function ActivityCard({
  label,
  value,
  icon,
  color,
}: {
  label: string;
  value: number | string;
  icon: IconDefinition;
  color: string;
}) {
  return (
    <Flex
      width="100%"
      py={6}
      px={4}
      justifyContent="space-between"
      alignItems="center"
      transition="all 0.3s"
      _hover={{ bg: `${color}10` }}
    >
      <Flex alignItems="center">
        <Icon
          as={FontAwesomeIcon}
          icon={icon}
          color={color}
          fontSize="2xl"
          mr={4}
        />
        <Text fontSize="lg" fontWeight="medium" color="gray.700">
          {label}
        </Text>
      </Flex>
      <Text fontSize="2xl" fontWeight="bold" color={color}>
        {value}
      </Text>
    </Flex>
  );
}

function ReviewSliders({ profileData }: { profileData: ProfileResponse }) {
  const { reviewData } = profileData;
  console.log(reviewData);
  const { slider } = reviewData;
  return (
    <>
      <GradientSlider
        value={slider.political}
        colorLeft="#BAE1FF"
        colorRight="#FFB3BA"
        labelLeft="liberal"
        labelRight="conservative"
        active={slider.political !== undefined}
      />
      {/* <GradientSlider
        value={slider.kinkiness}
        colorLeft="#FFFDD0"
        colorRight="#FF69B4"
        labelLeft="vanilla"
        labelRight="kinky"
        active={slider.kinkiness !== undefined}
      /> */}
    </>
  );
}

function AddReviewButton({
  profileData,
  onReview,
}: {
  profileData: ProfileResponse;
  onReview: () => void;
}) {
  const hasReviewed = profileData?.reviewData?.hasReviewed;
  return (
    <Flex
      color={"white"}
      bg={"pink.400"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      style={{
        fontSize: "1.3rem",
        position: "absolute",
        bottom: 20,
        right: 20,
        width: "60px",
        height: "60px",
        borderRadius: "50%",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        cursor: "pointer",
        zIndex: 1000,
      }}
      _hover={{
        transform: "scale(1.05)",
        transition: "transform 0.2s ease-in-out",
      }}
      _active={{
        transform: "scale(0.95)",
        transition: "transform 0.1s ease-in-out",
      }}
      onClick={onReview}
    >
      {!hasReviewed ? (
        <Flex flexDirection={"column"} alignItems={"center"} gap={0}>
          <FontAwesomeIcon icon={faFeather} size={"xl"} />
          <Text fontSize={"0.5em"} fontWeight={"bold"}>
            review
          </Text>
        </Flex>
      ) : (
        <Flex flexDirection={"column"} alignItems={"center"} gap={0}>
          <FontAwesomeIcon icon={faEdit} size={"xl"} />
          <Text fontSize={"0.5em"} fontWeight={"bold"}>
            edit
          </Text>
        </Flex>
      )}
    </Flex>
  );
}

function ProfileNewInquiry({
  profileData,
  navigate,
  associateProfile,
}: {
  profileData: ProfileResponse;
  navigate: (path: string) => void;
  associateProfile: (profileId: string) => void;
}) {
  return (
    <Flex direction="column" align="center" width="100%" height="100vh" gap={5}>
      <ProfileHeader profileData={profileData} reviewMode />
      <Flex
        direction="column"
        align="center"
        justify="center"
        width="100%"
        px={4}
        gap={4}
      >
        <Flex flexDirection={"column"} gap={0}>
          <Text
            fontSize="xl"
            fontWeight="semibold"
            textAlign="center"
            color="gray.800"
          >
            is this your date?
          </Text>
          <Text
            fontSize="md"
            fontWeight="medium"
            color="gray.500"
            textAlign={"center"}
          >
            selecting yes will deduct 1 from your view limit
          </Text>
        </Flex>
        <Flex direction="column" width="100%" maxWidth="300px" gap={4}>
          <Button
            colorScheme="teal"
            width="100%"
            size="lg"
            borderRadius="full"
            leftIcon={<FontAwesomeIcon icon={faCheckCircle} />}
            _hover={{ transform: "translateY(-2px)", boxShadow: "md" }}
            transition="all 0.2s"
            onClick={() => associateProfile(profileData.profileId)}
          >
            yes, it's them
          </Button>
          <Button
            colorScheme="red"
            width="100%"
            size="lg"
            borderRadius="full"
            variant="outline"
            leftIcon={<FontAwesomeIcon icon={faTimesCircle} />}
            _hover={{ bg: "red.50", transform: "translateY(-2px)" }}
            transition="all 0.2s"
            onClick={() => navigate("/select")}
          >
            no, it's not
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

function ViewLimitReached({ onReview }: { onReview: () => void }) {
  return (
    <Flex flexDirection={"column"} gap={2}>
      <Box mb={6}>
        <Icon
          as={FontAwesomeIcon}
          icon={faExclamationCircle}
          fontSize="4xl"
          color="pink.500"
          mb={4}
        />
        <Text fontSize="xl" fontWeight="bold" color="gray.800" mb={2}>
          View Limit Reached
        </Text>
        <Text fontSize="md" color="gray.600">
          Leave a review to unlock more profile views
        </Text>
      </Box>
      <Button
        colorScheme="pink"
        size="lg"
        width="100%"
        maxWidth="300px"
        height="56px"
        borderRadius="full"
        fontWeight="semibold"
        leftIcon={<FontAwesomeIcon icon={faFeather} />}
        onClick={onReview}
        _hover={{ transform: "translateY(-2px)", boxShadow: "md" }}
        transition="all 0.2s"
      >
        Leave a Review
      </Button>
    </Flex>
  );
}

function ProfileRatingBody({
  profileData,
  isNew,
  resetState,
  navigate,
  onReview,
}: {
  profileData: ProfileResponse;
  isNew: boolean;
  resetState: () => void;
  navigate: (path: string) => void;
  onReview: () => void;
}) {
  if (!profileData.reviewData) {
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="100%"
        px={4}
        py={8}
        maxWidth="100%"
        mx="auto"
        gap={5}
      >
        <ProfileHeader profileData={profileData} />
        <ViewLimitReached onReview={onReview} />
      </Flex>
    );
  }

  return (
    <Flex flexDirection={"column"} gap={5}>
      <AddReviewButton profileData={profileData} onReview={onReview} />
      <ProfileHeader profileData={profileData} />
      <ActiveOn profileData={profileData} />
      <ProfileStars profileData={profileData} />
      <ProfileActivity profileData={profileData} />
      <Flex minHeight={"20px"} width={"100%"} />
    </Flex>
  );
}

export default function ProfileView({ onReview }: { onReview: () => void }) {
  const { loadingProfileData, profileData } = useProfile();
  const { searchData, resetState, associateProfile, loadingAssociation } =
    useImageFlow();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const isNew = state?.isNew;
  const isAssociated = state?.isAssociated;

  const backPath = state?.backPath;

  if (loadingProfileData || !profileData || loadingAssociation) {
    return (
      <Flex
        height={"100dvh"}
        width={"100vw"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Spinner size="xl" />
      </Flex>
    );
  }

  console.log(profileData);

  return (
    <Flex
      width={"100vw"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      padding={10}
      display={"flex"}
      flexDirection={"column"}
      gap={10}
      overflowY={"scroll"}
      overflowX={"hidden"}
      height={"100%"}
    >
      <Header
        leftButton={() => {
          // TODO: pass in a navigate flag if a profile was created
          if (backPath) {
            navigate(backPath);
            return;
          }
          if (isNew === false && isAssociated === false) {
            navigate("/select");
          } else {
            resetState();
            navigate("/");
          }
        }}
        leftButtonIcon={
          (searchData && searchData.matches.length > 0) || backPath
            ? faChevronLeft
            : faHome
        }
      />
      {isAssociated === false ? (
        <ProfileNewInquiry
          profileData={profileData}
          navigate={navigate}
          associateProfile={associateProfile}
        />
      ) : (
        <ProfileRatingBody
          profileData={profileData}
          isNew={isNew}
          resetState={resetState}
          navigate={navigate}
          onReview={onReview}
        />
      )}
    </Flex>
  );
}
