import { Box, Button, Card, Flex, Icon, Text, VStack } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faExclamationCircle,
  faNoteSticky,
  faSearch,
  faUpload,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FileUploader } from "react-drag-drop-files";
import { useDateRate } from "../../providers/use-daterate";

const MainContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

function FileUpload({
  onChange,
  title,
  icon,
  description,
  colors,
}: {
  onChange: (file: File) => void;
  title: string;
  icon: IconDefinition;
  description: string;
  colors: {
    bg: string;
    hoverBg: string;
    activeBg: string;
    boxShadow: string;
  };
}) {
  return (
    <FileUploader
      handleChange={(file: File) => onChange(file)}
      name="file"
      types={["JPG", "PNG", "JPEG", "HEIC"]}
    >
      <Card
        width="90vw"
        bg={colors.bg}
        color="white"
        borderRadius="full"
        boxShadow={colors.boxShadow}
        transition="all 0.3s ease"
        cursor="pointer"
        _hover={{
          bg: colors.hoverBg,
          boxShadow: colors.boxShadow,
        }}
        _active={{
          transform: "scale(0.98)",
        }}
      >
        <Flex
          direction="row"
          align="center"
          justify="space-between"
          height="60px"
          px={4}
        >
          <Flex align="center">
            <Flex
              bg="white"
              borderRadius="full"
              p={2}
              mr={3}
              boxShadow="0 2px 6px rgba(255, 105, 180, 0.2)"
            >
              <FontAwesomeIcon icon={icon} size="lg" color={colors.bg} />
            </Flex>
            <Flex flexDirection="column">
              <Text
                fontSize="md"
                fontWeight="bold"
                color="white"
                letterSpacing="wide"
              >
                {title}
              </Text>
              <Text fontSize="xs" color="white">
                {description}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </FileUploader>
  );
}

interface ViewLimitReachedProps {
  onReviewClick: () => void;
}

const ViewLimitReached: React.FC<ViewLimitReachedProps> = ({
  onReviewClick,
}) => (
  <Box p={3} maxWidth="90vw" width="100%" mx="auto">
    <VStack spacing={2} align="center">
      <Text fontSize="lg" fontWeight="bold" textAlign="center" color="gray.800">
        <Icon
          as={FontAwesomeIcon}
          icon={faExclamationCircle}
          color="pink.500"
          paddingRight={2}
        />
        View Limit Reached
      </Text>
      <Text fontSize="sm" textAlign="center" color="gray.600">
        Review a past date to view more profiles.
      </Text>
    </VStack>
  </Box>
);

export function DragDropFileReview({
  onChange,
}: {
  onChange: (file: File) => void;
}) {
  const { userDetailsQuery } = useDateRate();

  const remainingViews =
    userDetailsQuery?.data?.metadata.viewData.remainingViews;

  return (
    <MainContainer>
      {remainingViews !== 0 ? (
        <FileUpload
          colors={{
            bg: "#FF69B4",
            hoverBg: "#FF1493",
            activeBg: "#FF1493",
            boxShadow: "0 4px 4px rgba(255, 105, 180, 0.2)",
          }}
          onChange={onChange}
          title="Search a potential date"
          description="Upload a picture of your potential date"
          icon={faSearch}
        />
      ) : (
        <ViewLimitReached onReviewClick={() => {}} />
      )}
      <FileUpload
        colors={{
          bg: "#FF69B4",
          hoverBg: "#FF1493",
          activeBg: "#FF1493",
          boxShadow: "0 4px 4px rgba(255, 105, 180, 0.2)",
        }}
        onChange={onChange}
        title="Review a past date"
        description="Upload a picture of your past date"
        icon={faComment}
      />
    </MainContainer>
  );
}
