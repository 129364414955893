import React, { createContext, useContext } from "react";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { API_URL } from "../constants";
import { useAuthRef } from "./use-auth";
import { RecentlyViewedProfile, UserDetails } from "../types/user";

type Metadata = {
  sliderConfig: { [sliderKey: string]: number }; // 1 - 100
  ratingConfig: { [ratingKey: string]: number }; // 1 - 5
  flagConfig: { [flagKey: string]: string[] }; // FLAG_TYPE: 'GREEN', 'YELLOW', 'RED'
};

type DateRateRefContextType = {
  metadata?: Metadata;
  viewedProfilesQuery?: UseQueryResult<RecentlyViewedProfile[], Error>;
  userDetailsQuery?: UseQueryResult<UserDetails, Error>;
  loading: boolean;
};

const DateRateContext = createContext<DateRateRefContextType>({
  metadata: undefined,
  viewedProfilesQuery: undefined,
  userDetailsQuery: undefined,
  loading: false,
});

export const useDateRate = () => {
  return useContext(DateRateContext);
};

export const DateRateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { jwt } = useAuthRef();

  const { data, isLoading } = useQuery({
    queryKey: ["dateRate"],
    queryFn: async () => {
      const response = await fetch(`${API_URL}/v1/metadata`, {
        method: "GET",
      });
      return response.json();
    },
  });

  const viewedProfilesQuery = useQuery({
    queryKey: ["viewedProfiles", jwt],
    queryFn: async () => {
      if (!jwt) {
        return null;
      }
      const response = await fetch(`${API_URL}/v1/user/get-viewed`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      });
      return (await response.json()).recentlyViewed;
    },
  });

  const userDetailsQuery = useQuery({
    queryKey: ["userDetails", jwt],
    queryFn: async () => {
      if (!jwt) {
        return null;
      }
      const response = await fetch(`${API_URL}/v1/user/details`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      });
      return await response.json();
    },
  });

  console.log(userDetailsQuery.data);

  return (
    <DateRateContext.Provider
      value={{
        metadata: data,
        viewedProfilesQuery,
        userDetailsQuery,
        loading: isLoading,
      }}
    >
      {children}
    </DateRateContext.Provider>
  );
};
