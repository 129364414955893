import React, { createContext, useContext, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useProfile } from "./use-profile";
import { useAuthRef } from "./use-auth";
import { API_URL } from "../constants";
import { REVIEW_CATEGORIES } from "../data/review";
import { ReviewData } from "../types/review";

type ReviewRefContextType = {
  onNext: (data: ReviewData, callback?: () => void) => void;
  // reviewState: ReviewState;
  // setReviewState: (state: ReviewState) => void;
  reviewIndex: number;
  setReviewIndex: (index: number) => void;
  reviewData: ReviewData;
  setReviewData: (data: ReviewData) => void;
  onBack: () => void;
};

const ReviewContext = createContext<ReviewRefContextType>({
  onNext: (data: ReviewData, callback?: () => void) => {},
  reviewData: {
    slider: {},
    ratings: {},
    flags: {},
    dropdown: {},
  },
  setReviewData: () => {},
  reviewIndex: 0,
  setReviewIndex: () => {},
  onBack: () => {},
  // reviewState: "stars",
  // setReviewState: () => {},
});

export const useReviewRef = () => {
  return useContext(ReviewContext);
};

export const ReviewProvider = ({ children }: { children: React.ReactNode }) => {
  const [reviewIndex, setReviewIndex] = useState<number>(0);
  const [reviewData, setReviewData] = useState<ReviewData>({
    slider: {},
    ratings: {},
    flags: {},
    dropdown: {},
  });
  const { profileData, refetchProfileData } = useProfile();
  const { jwt } = useAuthRef();

  const { mutate: submitReview } = useMutation({
    mutationFn: async ({
      reviewData,
      callback,
    }: {
      reviewData: any;
      callback?: () => void;
    }) => {
      if (!jwt) throw new Error("No JWT");
      if (!profileData) throw new Error("No selected profile");
      const reqData = reviewData;
      const response = await fetch(`${API_URL}/v1/user/review`, {
        method: "POST",
        body: JSON.stringify({
          profileId: profileData.profileId,
          reviewData: reqData,
        }),
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        return callback;
      }
      throw new Error("Failed to submit review");
    },
    onError: (error) => {
      console.log(error);
    },
    onSuccess: (callback) => {
      refetchProfileData();
      callback?.();
    },
  });

  const onNext = (
    additionalData?: Partial<ReviewData>,
    callback?: () => void
  ) => {
    console.log("onNext", additionalData);
    setReviewData((prev) => {
      const nextReviewData = { ...prev, ...additionalData };
      setReviewIndex((prev) => {
        const currentIndex = reviewIndex;
        const nextIndex = currentIndex + 1;
        console.log("nextIndex", nextIndex);
        if (nextIndex === Object.keys(REVIEW_CATEGORIES).length + 1) {
          console.log("SUBMIT");
          if (
            Object.values(nextReviewData.dropdown).some((value) => value) ||
            Object.values(nextReviewData.ratings).some((value) => value)
          ) {
            submitReview?.({ reviewData: nextReviewData, callback });
          } else {
            callback?.();
          }
        }
        return nextIndex;
      });
      return nextReviewData;
    });
  };

  const onBack = () => {
    setReviewIndex((prev) => {
      const currentIndex = reviewIndex;
      const nextIndex = currentIndex - 1;
      return nextIndex;
    });
  };

  return (
    <ReviewContext.Provider
      value={{
        reviewIndex,
        setReviewIndex,
        reviewData,
        setReviewData,
        onNext,
        onBack,
      }}
    >
      {children}
    </ReviewContext.Provider>
  );
};
