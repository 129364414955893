import { ChakraProvider, ColorModeScript, extendTheme } from "@chakra-ui/react";
import { Router } from "./components/pages/Router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./providers/use-auth";

const queryClient = new QueryClient();

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
});

export default function App() {
  // 2. Wrap ChakraProvider at the root of your app
  return (
    <>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Router />
          </AuthProvider>
        </QueryClientProvider>
      </ChakraProvider>
    </>
  );
}
